import {getActionWithBaseUrl} from "@/utils/request";

export const initWeChatForwardConfiguration = (title, url, forwardImgUrl, wxdes) => {
  const forwardImg = forwardImgUrl || "https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/03/21/c07e399c0d9c4f6b92ce07b00b8c24a9.png"
  let baseURL = url || window.location.href;
  getActionWithBaseUrl('cvhSign', { url: baseURL }, 'https://med.drvoice.cn').then((res) => {
    const data = res.data
    window.wx.config({
      debug: false,
      appId: data.appId, // 必填，公众号的唯一标识
      timestamp: data.timestamp, // 必填，生成签名的时间戳
      nonceStr: data.nonceStr, // 必填，生成签名的随机串
      signature: data.signature, // 必填，签名
      jsApiList: ['checkJsApi', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo', 'onMenuShareQZone', 'openLocation', 'getLocation' /*'updateAppMessageShareData', 'updateTimelineShareData'*/],
      success: function () {

      },
      cancel: function () {},

    })
    window.imgurl = forwardImg;
    window.title = title || document.title;
    window.desc = wxdes || 'CHINA VALVE（HANGZHOU）';
    let params = {
      title: window.title,
      imgurl: window.imgurl,
      desc: window.desc,
      url: baseURL
    };
    console.log("wechat info:" + JSON.stringify(params));
    window.wx.ready(function () {
      window.wx.onMenuShareAppMessage({
        title: title,
        desc: wxdes || 'CHINA VALVE（HANGZHOU）',
        link: baseURL,
        imgUrl: forwardImg,
        success: function () {

        },
        cancel: function () {},
      })
      window.wx.onMenuShareTimeline({
        title: title,
        desc: wxdes || 'CHINA VALVE（HANGZHOU）',
        link: baseURL,
        imgUrl: forwardImg,
        success: function () {
        },
        cancel: function () {},
      })
      window.wx.onMenuShareQQ({
        title: title,
        desc: wxdes || 'CHINA VALVE（HANGZHOU）',
        link: baseURL,
        imgUrl: forwardImg,
        success: function () {
        },
        cancel: function () {},
      })
      window.wx.onMenuShareQZone({
        desc: wxdes || 'CHINA VALVE（HANGZHOU）',
        link: baseURL,
        imgUrl: forwardImg,
        success: function () {
        },
        cancel: function () {},
      })
      window.wx.onMenuShareWeibo({
        title: title,
        desc: wxdes || 'CHINA VALVE（HANGZHOU）',
        link: baseURL,
        imgUrl: forwardImg,
        success: function () {
        },
        cancel: function () {},
      })
    })
    window.wx.error(function(res){
      console.log("config信息验证失败", res);
    });

  })
}

